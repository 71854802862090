@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.rounded-image {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
        width: 150px;
        height: 150px;
        border-radius: 100%;
    }

    .rounded-image-text p {
        max-width: 240px;
    }
}
